@import "variables";
@import "custom-styles";

.header-section{
  border: none;
}
.se-main-banner{
    margin-top: 100px;
    padding: 50px 0;
    position: relative;
    .container{
      width: 100%;
      max-width: 1730px;
      padding: 0;
    }
    @media (max-width: $medium-laptop) {
      padding: 0 20px;
    }
    &:before{
      content: "";
      position: absolute;
      left: -300px;
      bottom: 0;
      width: 523px;
      height: 493px;
      background-image: url('../assets/images/wave-bg.png');
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
      rotate: 15deg;
    }
    &:after{
      content: "";
      position: absolute;
      top: 0;
      right: -400px;
      width: 523px;
      height: 493px;
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
      background-image: url('../assets/images/wave-bg.png');
      z-index: -1;
      rotate: 0deg;
    }
    .carousel-indicators{
      li{
        border-color: #193549 !important;
        &.active{
          background-color: #193549;
        }
      }
    }
    .banner-image{
    position: relative;
    width: 100%;
    height: 663px;
    @media (max-width: $small-tablet) {
      height: 60vh;
    }
    .slide-content{
      width: 100%;
      height: 100%;
      text-align: left;
      max-width: 1450px;
      margin: auto;
      overflow: hidden;
      @media (max-width:$max-width) {
        padding-left: 25px;
      }
      &.slide-content-1{
        overflow: visible;
      }
    }
    .b-slide-1 {
      .text-wrapper{
        max-width: 520px;
        margin-top: 195px;
        @media (max-width: $small-tablet) {
          margin-top: 125px;
        }
        h2{
          color: $textBlack;
          font-family: Poppins;
          font-size: 67px;
          font-style: normal;
          font-weight: 600;
          line-height: 99.26px; /* 148.149% */
          letter-spacing: -0.16px;
          @media (max-width: $small-tablet) {
            font-size: 37px;
            line-height:50px;
          }
        }
        h3{
          color: $textBlack;
          font-size: 58px;
          font-style: normal;
          font-weight: 300;
          line-height: 70px;
          letter-spacing: -0.32px;
          @media (max-width: $small-tablet) {
            font-size: 27px;
            line-height:40px;
          }
        }
        .primary-button{
          margin-top: 10px;
          padding: 16px !important;
          line-height: 10px;
        }
      }
  }
  .b-slide-2{
    .text-wrapper{
      max-width: 600px;
      margin-top: 195px;
      @media (max-width: $small-tablet) {
        margin-top: 125px;
      }
      span{
        color: $white;
        font-size: 45px;
        font-weight: 300;
        line-height: 70px;
        letter-spacing: -0.32px;
        padding-left: 0;
        @media (max-width: $small-tablet) {
          font-size: 27px;
          line-height:30px;
        }
      }
      h2{
        color: $white;
        font-size: 67px;
        font-style: normal;
        font-weight: 600;
        line-height: 99.26px; /* 148.149% */
        letter-spacing: -0.16px;
        @media (max-width: $small-tablet) {
          font-size: 37px;
          line-height:50px;
        }
      }
      h3{
        color: $white;
        font-size: 58px;
        font-style: normal;
        font-weight: 400;
        line-height: 70px;
        letter-spacing: -0.32px;
        @media (max-width: $small-tablet) {
          font-size: 27px;
          line-height:40px;
        }
      }
      .primary-button{
        margin-top: 10px;
        color: $white;
        padding: 16px !important;
        line-height: 10px;
      }
    }
  }
    .banner-image-wrapper {
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
      border-radius: 36px;
      overflow: hidden;
      img {
        width: 100%;
        height: 100%;
        object-fit: fill;
        @media (max-width: $large-laptop) {
          object-fit: cover;
        }
      }
      &.slide-image-1{
        img{
          @media (max-width: $large-tablet) {
            object-position: -70px center;
          }
        }
      }
    }
    .carousel-content {
      height: 100%;
      position: relative;
      z-index: 2;
    }
    .slide-content.slide-content-1 {
      padding-top: 100px !important;
      @media (max-width:$small-tablet) {
        padding-top: 35px !important;
      }
      @media (max-width:$iphone-pro-max) {
        padding-top: 30px !important;
      }
      h2 {
        font-size: 50px;
        color: #fff;
        max-width: 640px;
        font-weight: bold;
        border-left: 5px solid #06AEB8;
        padding-left: 15px;
        @media (max-width:$small-tablet) {
          font-size: 35px;
        }
    }
    h3 {
        font-size: 45px;
        color: #fff;
        max-width: 750px;
        margin-top:25px;
        @media (max-width:$small-tablet) {
          font-size: 25px;
        }
    }
    .primary-button{
      margin-top: 200px;
      color: #fff;
      padding: 0 15px;
      @media (max-width:$small-tablet) {
        margin-top: 170px;
      }
    }  
  }  
}
  .carousel-indicators{
    display: flex;
    justify-content: center;
    align-items: center;
    bottom: 30px;
    left: 0;
    right: 0;
    margin: 0 auto;
    li{
      overflow:hidden;
      position: relative;
      display: inline-block;
      vertical-align: top;
      width: 20px;
      height: 7px;
      margin: 0 3px;
      border-radius: 4px;
      border: solid 1px $white;
      background-color: transparent;
      outline: none;
      cursor: pointer;
      transition: width 0.5s;
      &.ft-active{
        background-color: $white;
        width: 37px;
      }
    }
  }
}
.se-about-section{
  padding: 155px 0 90px 0;
  position: relative;
  @media (max-width: $small-tablet) {
    padding:100px 0;
    img{
      max-width: 100%;
    }
  }
  &:before{
    content: '';
    position: absolute;
    left: -40px;
    top: 216px;
    width: 190px;
    height: 270px;
    background-image: url('../assets/images/circle.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    }
    &:after{
        content: ''; 
        position: absolute;
        bottom: 80px;
        right: 0;
        width: 206px;
        height: 47px;
        background-size: contain;
        background-position: center right;
        background-repeat: no-repeat;
        background-image: url('../assets/images/right-coming-object.png');
    }
  .container{
    max-width: 1450px;
    width: 100%;
    h2{
      color:$textBlack;
      font-family: $font;
      font-size: 41px;
      font-style: normal;
      font-weight: 600;
      line-height: 148.748%;
      margin-bottom: 30px;
      @media (max-width: $small-tablet) {
        font-size: 28px;
      }
    }
    p{
      color: $textBlack;  
      font-family: $font;
      font-size: 20px;
      font-style: normal;
      font-weight: 400;
      line-height: 30.06px; /* 150.3% */
      letter-spacing: -0.09px;
      margin-bottom: 30px;
    }
    img{
      @media (max-width: $small-phone) {
        max-width: 100%;
        margin-top: 0 !important;
        margin-bottom: 50px;
      }
    }
  }
}
.se-meta-section{
  padding: 0 0 160px 0;
  .container{
    max-width: 1450px;
    width: 100%;
    h2{
      color: $textBlack;
      text-align: center;
      font-family: $font;
      font-size: 48px;
      font-style: normal;
      font-weight: 600;
      line-height: 66px;
    }
    .se-meta {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 45px;
      border-radius: 36px;
      text-align: center;
      margin-top: 75px;
      position: relative;
      background-color: $white;
      @media (max-width: $large-tablet) {
        flex-wrap: wrap;
        justify-content: center;
        gap: 50px;
      }
      &:after{
        background: linear-gradient(90deg, #DEF9EE 0%, #E3EAFB 22%, #F6E2EE 46.5%, #FAE9E5 75%, #F8FBE8 100%);
        filter: blur(26.25px);
        content: "";
        position: absolute; 
        inset: 0px;
        transform: translate3d(0px,0px,-1px);
        pointer-events: none;
        z-index: -1;
      } 
      .se-meta-label{
        text-align: center;
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
        line-height: 28px;
        color: #A1A1AA;
        margin-bottom: 14px;
      }
      .se-meta-number{
        text-align: center;
        font-size: 60px; 
        font-weight: 600;
        line-height: 66px;
        background: linear-gradient(98deg, #06AEB8 0%, #193549 100%);
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
      >div{
        width:250px;
      }
    }
    .se-meta-btn{
      text-align: center;
      margin-top: 98px;
    }
  }
}


.why-se-section {
  background: $white url("../assets/images/gradient -bg.png") no-repeat top center; 
  position: relative;
  padding: 50px 0;
  overflow: visible;
  height: 449px;
  background-size: cover;
  margin-bottom: 77px;
  .container{
    width: 100%;
    max-width: 1592px;
    padding: 0;
    overflow: visible;
    h2{
      color: #193549;
      text-align: center;
      font-size: 41px;
      font-weight: 600;
      line-height: 45.1px;
      margin-bottom: 50px;
    }
    h5{
      color: #193549;
      text-align: center;
      font-size: 20px;
      font-weight: 400;
      line-height: 28.57px; 
    }
    .all-services-list {
      .main-heading {
        text-align: center;
      }
      .common-parah{
        text-align: center;
        margin: 30px auto 0;
        max-width: 950px;
      }
    }
    // accordion slider styling
    .service-acco-slider {
      position: relative;
      margin-bottom: 40px;
    }
    .acco-slider {
      width: 70%;
      display: flex;
      column-gap: 15px;
      border-radius: 10px;
      overflow-x: auto;
      overflow-y: hidden;
      padding: 42px 0px;
      box-sizing: content-box;
      margin: auto;
      &::-webkit-scrollbar {
        width: 10px;
        height: 10px;
        background-color: $white2;
        display: none;
      }
      &::-webkit-scrollbar-thumb {
        border-radius: 10px;
        height: 10px;
        background-image: $gradient;
      }
      @media (max-width: $small-tablet) {
        width: 85%;
      }
    }
    .expand-wrapper {
      position: relative;
      flex:200px 0 0;
      
      .close-expanded{
        border-color: #06AEB8;
        i{
          color: #06AEB8;
        }
      }
    }
    .acco-slide-closed {
      min-width: 186px;
      height: 348px;
      flex: 1 1;
      transition-property: flex-grow, min-width;
      transition-duration: 0.3s;
      transition-timing-function: ease-in;
      margin: 0;
      color: transparent;
      font-size: 20px;
      position: relative;
      cursor: pointer;
      .gradient-border {
        height: 100%;
        border: 0;
        background: $white;
        box-shadow: 0px 4px 21px 1px rgba(48, 123, 196, 0.10);
        .gradient-border-inner {
          height: 100%;
          align-items: center;
          justify-content: start;
          .closed-slide-icon {
            object-fit: contain;
            margin-top: 90px;
            transition: all .2s
          }
          .closed-slide-text{
            font-size: 20px;
            font-weight: 500;
            letter-spacing: -0.39px;
            text-align: center;
            color: #274760;
            line-height: 25px;
            position: absolute;
            bottom: 24px;
            padding: 0 5px;
          }
        }
      }
      .expanded-gradient-bg{
        border-radius: 10px;
        width: 100%;
        height: 100%;
        background-color: $white;
        display: none;
        position: relative;
        &:after{
          background: linear-gradient(90deg, #DEF9EE 0%, #E3EAFB 22%, #F6E2EE 46.5%, #FAE9E5 75%, #F8FBE8 100%);
          filter: blur(26.25px);
          content: "";
          position: absolute; 
          inset: 0px;
          transform: translate3d(0px,0px,-1px);
          pointer-events: none;
        }
        .content-section{
          padding: 34px 24px;
          z-index: 10;
          height: 100%;
          background: $white;
          border-radius: 36px;
          position: relative;
          .title-row {
            display: flex;
            align-items: center;
            margin-bottom: 40px;
            position: relative;
            .service-title-icon {
              width: 58px;
              height: 58px;
              object-fit: contain;
              //filter: brightness(3);
              margin-right: 24px;
            }
            .service-main-heading {
              color: #274760;
              font-size: 22px;
              font-weight: 500;
              line-height: 30px;
              text-align: left;
              @media (max-width: $large-tablet) {
                font-size: 32px;
              }
              @media (max-width: $iphone-pro-max) {
                font-size: 28px;
              }
            }
          }
          .common-parah {
            width: 320px;
            text-align: left;
          }
           
          .slide-down-btn {
            background: transparent;
            color: $charcoal-text;
            border: 1px solid $charcoal-text;
            width: 34px;
            height: 34px;
            border-radius: 17px;
            margin-left: 34px;
            span{
              display: block;
            }
            &.slide-up {
              span{
                transform: rotate(180deg);
                transition: transform 0.5s ease-in-out;
              }
            }
            &.slide-down {
              span{
                transform: rotate(0deg);
                transition: transform 0.5s ease-in-out;
              }
            }
          }
        }
      }
      &:hover{
       .gradient-border{
         .gradient-border-inner{
           .closed-slide-icon {
              transform: scale(1.3);
           }
         }
       }
     }
      &.slide--expanded{
        flex: 100 1;
        min-width: 280px;
        cursor: default;
        .gradient-border{
          display: none;
        }
        .expanded-gradient-bg {
          display: block;
        }
      }
    }
    .scroll-btn {
      width: 5%;
      position: absolute;
      top: 40px;
      bottom: 40px;
      border: 0;
      opacity: 0;
      z-index: 99;
      @media (max-width: $large-laptop) {
        opacity: 1;
      }
      .fa-solid{
        color: #FFF;
        font-size: 40px;
      }
      &:hover{
        opacity: 1;
      }
      &.hide{
        display: none;
      }
    }
    .scroll-left-btn {
      left: 0;
      background: linear-gradient(90deg, rgba(0,0,0,0.3) 0%, rgba(255,255,255,0) 100%);
    }
    .scroll-right-btn {
      right: 0;
      background: linear-gradient(90deg, rgba(255,255,255,0) 0%, rgba(0,0,0,0.3) 100%);
      @media (min-width: $large-laptop) {
        display: none;
      }
    }
    .btn-wrapper{
      .primary-button{
        margin: 0 auto;
        display: block;
      }
    }
    .acco-slide-closed + .close-expanded {
      display: none;
    }
    .slide--expanded + .close-expanded {
      display: flex;
    }
  } 
}
.se-features-section{
  padding: 100px 0;
  .container{
    width: 100%;
    max-width: 1490px;
    padding: 0;
    h2{
      color: #193549;
      text-align: center;
      font-size: 41px;
      font-weight: 600;
      line-height: 45.1px;
      margin-bottom: 24px;
      text-align: center;
      margin-bottom: 85px;
      padding: 0 15px;
    } 
    .se-features-list{
      padding: 0 15px;
      ul{
        padding: 0;
        margin: 0;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        column-gap: 80px;
        row-gap: 60px;
        @media (max-width: $small-tablet) {
          row-gap: 30px;
        }
        li{
          width: 433px;
          height: 100px;
          border-radius: 15px;
          border: 1px solid var(--Primary, #06AEB8);
          background: #FFF;
          display: flex;
          align-items: center;
          justify-content: start;
          gap: 28px;
          padding: 20px 38px;
          position: relative;
          transition: all 0.3s;
          &:hover{
            &:after{
              content: '';
              position: absolute;
              width: 100%;
              height: 100%;
              border-radius: 36px;
              background: linear-gradient(90deg, #DEF9EE 0%, #E3EAFB 22%, #F6E2EE 46.5%, #FAE9E5 75%, #F8FBE8 100%);
              filter: blur(26.25px);
              left:0;
              top: 0;
              z-index: -1;
            }
          }
          @media (max-width:$small-laptop) {
            width: 43%;
          }
          @media (max-width:$large-phone) {
            width: 460px;
            max-width: 100%;
          }
          img{
            transition: all 0.3s;
          }
          &:hover{
            img{
              transform: scale(1.1);
            }
          }
          .sef-icon-box{
            width:  60px;
            height: 65px;
          }
          .sef-text{
            color: #274760;
            font-family: Poppins;
            font-size: 20px;
            font-style: normal;
            font-weight: 500;
            line-height: 25px; /* 125% */
            letter-spacing: -0.2px;
          }
        }
      }
    }
  }
}
.zoomEffect{
  transition:all 0.3s;
  &:hover{
    transform: scale(1.1);
  }
}
.specialty-section{
  background: #F8FDFD;
  padding: 75px 0 75px 0;
  position: relative;
  &:after{
    content: '';
    position: absolute;
    right: -40px;
    top: 30px;
    width: 190px;
    height: 270px;
    background-image: url('../assets/images/circle.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    rotate: 240deg;
    z-index: 1;
    }
    &:before{
      content: ''; 
      position: absolute;
      bottom: 200px;
      left: -30px;
      width: 206px;
      height: 47px;
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
      background-image: url('../assets/images/right-coming-object.png');
      rotate: 180deg;
    }
  @media (max-width: $small-tablet) {
    padding: 50px 0 50px 0;
  }
  .container{
    width: 100%;
    max-width:1557px;
  }
  h2{
    color: #193549;
      text-align: center;
      font-size: 41px;
      font-weight: 600;
      line-height: 45.1px;
      margin-bottom: 24px;
      text-align: center;
      margin-bottom: 85px;
  }
  .multipletab{
    width:100%;
    margin:0 auto;
    position:relative;
    .scroller-btn{
      position: absolute;
      top: 15px;
      z-index: 10;
      cursor: pointer;
      @media (max-width:$large-laptop){
        top: -30px;
      }
      &.left{
        left: -40px;
        rotate: 180deg;
        @media (max-width:$large-laptop){
        left: 0;
        }
      }
      &.right{
        right: -30px;
        @media (max-width:$large-laptop){
          right: 0;
        }
      }
    }
  }
  .tab-buttons{
    display: flex;
    width: 100%;
    column-gap: 56px;
    align-items: center;
    padding:0 0 40px 0;
    border-bottom:1px solid rgba(113, 113, 113, 0.50);
    overflow: auto;
    &::-webkit-scrollbar {
      width: 4px;
      height: 4px;
      background-color: $white2;
      display: none;
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 10px;
      height: 5px;
      background-image: $gradient;
    }
  }
  .tab-buttons>span{
    cursor:pointer;
    color: #193549;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 29px !important; 
  }
  .tab-buttons>span.ft-active{
    color:$aqua3;
    text-decoration: underline;
  }
  .tab-content{
    position:relative;
    margin-top: 60px;
    overflow: hidden;
    .st-silde-item{
      height: 500px;
      @media(max-width: $small-tablet) {
        height: auto;
      }
      img{
        max-width: 100%;
      }
      h3{
        color: #06AEB8;
        font-size: 29px;
        font-weight: 500;
        line-height: 45.1px; 
        margin-bottom: 15px;
        font-family: $font;
      }
      p{
        color: $textBlack;
        font-size: 20px;
        font-weight: 400;
        line-height: 30.06px;
        letter-spacing: -0.09px;
        margin-bottom: 30px;
        font-family: $font;
        strong{
          font-family: $font;
          font-weight: 600;
        }
      }
    }
  }
  .tab-content>div{
    position:absolute;
    background: #F8FDFD;
  }
  .tab-nav{
    position: absolute;
    top: 20px;
    right: -15px;
  }
  .multipletab:hover .tab-nav{
    opacity:1;
  }
  .tab-nav>span{
    position:absolute;
    top:50%;
    cursor:pointer;
    display:block;
    height:35px;
    width:35px;
  }
  .tab-nav>span.next{
    display: none;
  }
  .tab-nav>span.prev{
    left:10px;
    width: 24px;
    height: 24px;
    background:url(../assets/images/tabler_arrow-up.png) no-repeat center center;
  }
}
.se-cilents-logos{
  padding: 140px 0;
  background: #F8FDFD;
  @media (max-width:$small-tablet) {
    padding: 50px 0;
  }
  .container{
    max-width: 1455px;
    width: 100%;
    h2{
      color: $textBlack;
      font-size: 48px;
      font-style: normal;
      font-weight: 600;
      line-height: 45.1px; /* 93.958% */
      margin-bottom: 67px;
    }
    p{
      color: $textBlack;
      font-size: 20px;
      font-style: normal;
      font-weight: 400;
      line-height: 28.57px;
      margin-bottom: 25px;
    }
    .marquee-wrapper{
      overflow: hidden;
      width: 3000px;
      display: flex;
      flex-direction: column;
      gap: 30px;
      .marquee-content {
        display: flex;
        animation: scrolling 20s linear infinite running;
        align-items: center;
        &:hover{
          animation-play-state: paused;
        }
      }
      .marquee-content-ltr {
        display: flex;
        animation: scrollltr 20s linear infinite running;
        align-items: center;
        &:hover{
          animation-play-state: paused;
        }
      }
      .marquee-item {
        margin: 5px 24px;
        width: 180px;
        height: 75px;
        flex: 0 0 180px;
        display: flex;
        justify-content: center;
        align-items: center;
        img {
          max-width: 100%;
          max-height: 100%;
          width: auto !important;
          // filter: grayscale(100%);
        }
      }
    }
  }
}
.se-testimonial-section{
  .container{
    width: 100%;
    max-width: 1455px;
    .owl-carousel{
      .owl-nav{
        .owl-next{
          opacity: 0.5;
          &:before{
            content: '';
            background:url(../assets/images/slider-arrow.svg);
            width: 40px;
            height: 30px;
            background-repeat: no-repeat;
          }
          &:hover{
            opacity: 1;
          }
        }
        .owl-prev{
          opacity: 0.5;
          rotate: 180deg;
          &:before{
            content: '';
            background:url(../assets/images/slider-arrow.svg);
            width: 40px;
            height: 30px;
            background-repeat: no-repeat;
          }
          &:hover{
            opacity: 1;
          }
        }
      }
    }
  }
  padding: 90px 0;
  .se-testimonial-header{
    padding: 80px 0;
    position: relative;
    &:before{
      content:'';
      width: 143px;
      height: 121px;
      background: url(../assets/images/quote.svg) no-repeat;
      position: absolute;
      left: -50px;
      top: 10px;
      display: block;
    }
    h2{
      color: #000;
      font-size: 48px;
      font-style: normal;
      font-weight: 600;
      line-height: 66px;
    }
  }
  .owl-carousel{
    width: 2000px;
    @media (max-width:$small-laptop) {
      width: 100%;
    }
    .se-testimonial{
      width: 555px;
      @media (max-width:$small-phone) {
        width: 100%;
      }
      .se-testimonial-box{
        background:#fff;
        border-radius: 36px;
        position: relative;
        height:440px;      
        padding: 36px 36px 90px 36px;
        color: #000;
        font-size: 22px;
        font-style: normal;
        font-weight: 400;
        line-height: 36px;
        text-align: left;
        margin-bottom: 75px;
        @media (max-width:$small-phone) {
          height: auto;
        }
        &:after{
          content: '';
          position: absolute;
          width: 100%;
          height: 100%;
          border-radius: 36px;
          background: linear-gradient(90deg, #DEF9EE 0%, #E3EAFB 22%, #F6E2EE 46.5%, #FAE9E5 75%, #F8FBE8 100%);
          filter: blur(26.25px);
          left:0;
          top: 0;
          z-index: -1;
        }
        .se-testimonial-logo {
          width: 115px;
          // position: absolute;
          right: 80px;
          bottom: 30px;
          img{
            // filter: grayscale(1);
          }
        } 
        .se-testimonial-text{
          height: 325px;
          overflow: hidden;
        }
      }
      .se-testimonial-profile {
        display: flex;
        align-items: center;
        gap: 30px;
        justify-content: space-between;
        margin-top: 90px;
        position: absolute;
        bottom: 20px;
        width: 90%;
        .se-testimonial-avatar {
          width: 119px;
          height: 119px;
          border-radius: 50%;
          overflow: hidden;
        }
        .se-testimonial-desc {
          text-align: left;
          h4{
            color: #023047;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 18px;
          }
          p{
            color: #8F8F8F;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 17px;
          }
        }   
      }
    }
    .owl-nav {
      &.disabled{
        display: block !important;
        position: absolute;
        top: -175px;
        left: 1150px;
      }
  }
  }
}
.se-services-banner{
  padding:84px 0;
  background: rgba(243, 253, 253, 0.50);
  .carousel-cell {
    width: 1455px;  
    max-width: 70%;
    margin:0 100px;
    height: 516px; 
    position: relative;
    overflow: hidden;    
    border-radius: 36px;
    padding: 3px;
    @media (max-width: 1400px) {
      width: 100%;
      max-width: 90%;
      margin: 0 15px;
    }

    &:before{
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: -1;
      border-radius: inherit;
      background:linear-gradient(90deg, #DEF9EE 0%, #E3EAFB 22%, #F6E2EE 46.5%, #FAE9E5 75%, #F8FBE8 100%);
    }

    .banner-image{
      .banner-image-wrapper{
        height: calc(100% - 6px);
        position: absolute;
        overflow: hidden !important;
        border-radius: 36px;
        width: calc(100% - 6px);
        @media (max-width: $large-laptop) {
          position: absolute;
          top: 5px;
          left: 5px;
          width: calc(100% - 10px);
          overflow: hidden;
          height: calc(100% - 10px);
          border-radius: 36px;
          background-color: #fff; 
          img{
            max-width: unset;
            object-fit: cover;
          }
        }
      }
      .carousel-content {
        position: absolute;
        z-index: 99;
        left: 0;
        top: 0;
        height: 100%;
        padding-left: 140px;
        width: 615px;
        max-width: 100%;
        @media (max-width: $large-laptop) {
          position: relative;
          max-width: 80%;
          padding-left: 30px;
        }
        .text-wrapper {
            margin-top: 94px;
            padding-bottom: 20px;
            h2{
              color: $textBlack;
              text-align: left;
              font-size: 40px;
              font-weight: 600;
              line-height: 66px;
            }
            .detail-text-wrapper{
              height: 185px;
              display: flex;
              align-items: center;
              margin-bottom: 20px;
              overflow: hidden;
              p{
                color: #000;
                font-size: 20px;
                font-weight: 400;
                line-height: 30.06px;
                letter-spacing: -0.09px;
                vertical-align: text-bottom;
              }
            }
        }
    }
    }
  }
  .flickity-button,.flickity-page-dots{
    display: none;
  }
}
.se-partners-section{
  padding:175px 0 155px 0;
  background: $white;
  @media (max-width:$small-tablet) {
    padding: 50px 0;
  }
  .container{
    width: 100%;
    max-width: 1455px;
  }
  h2{
    color: $textBlack;
    text-align: center; 
    font-size: 48px;
    font-style: normal;
    font-weight: 600;
    line-height: 66px; 
    margin-bottom: 75px;
  }
  .se-partners-row{
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
    img{
      max-width: 150px;
      max-height: 80px;
    }
    @media (max-width: $small-tablet) {
      gap:35px;
      flex-wrap: wrap;
      justify-content: center;
    }
    @media (max-width: $small-phone) {
      flex-direction: column;
      gap: 30px;
     }
  }
  .se-partner-logo {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 25px;
    .se-partner-logoimg {
      height: 78px;
      display: flex;
      align-items: center;
      justify-content: center;
  }
    @media (max-width: $small-tablet) {
      width: 200px;
      height: auto;
      img{
        max-width: 100%;
      }
    }
    
    span{
      color: #000;
      font-size: 20px;
      font-weight: 400;
      line-height: normal;
      @media (max-width: $small-tablet) {
        font-size: 14px;
      }
    }
  }
  .divider{
    width: 1px;
    height: 135px;
    background: $textBlack;
    @media (max-width: $small-phone) {
      width: 114px;
      height: 1px;
     }
  }
}
.about-equinox-section{
  padding: 45px 0;
  background: rgba(243, 253, 253, 0.50);
  .container{

    h2{
      color: #000;
      text-align: center;
      font-size: 48px;
      font-weight: 600;
      line-height: 66px;
      margin-bottom: 30px;
    }
    p{
      color: #000;
      text-align: center;
      font-size: 20px;
      font-weight: 400;
      line-height: 30.06px; /* 150.3% */
      letter-spacing: -0.09px;
      margin-bottom: 50px;
    }
  }
  .about-equinox-btns{
    display:flex;
    justify-content: center;
    gap: 40px;
    @media (max-width:$phablet) {
      flex-direction: column;
      align-items: center;
    }
  }
}
.se-learn-more-btn{
  display: inline-flex;
  padding: 16px 20px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 50px;
  border: 1px solid #193549;
  color: #193549;
  transition: all 0.3s;
  &:hover{
    background: linear-gradient(269deg, #06AEB8 1.02%, #193549 101.57%);
    color: #fff;
    border-color: transparent;
  }
}
.se-hie-section{
  padding: 125px 0;
  .container{
    h2{
      color: #000;
      font-size: 44px;
      font-style: normal;
      font-weight: 600;
      line-height: 66px;
      margin-bottom: 45px;
    }
    p{
      color: #000;
      text-align: center;
      font-size: 20px;
      font-weight: 400;
      line-height: 30.06px;
      letter-spacing: -0.09px;
      margin-bottom: 25px;
    }
    h4{
      color: #000;
      text-align: center;
      font-size: 20px;
      font-weight: 600;
      line-height: 30.06px; 
      letter-spacing: -0.09px;
      margin-bottom: 35px;
    }
    .se-partners-row{
      display: flex;
      justify-content: space-around;
      align-items: center;
      margin: 70px auto;
      max-width: 790px;
      @media (max-width: $small-phone) {
        flex-direction: column;
        gap:30px;
       }
      .se-partner-logo {
        width: 200px;
        height: 85px;
        display: flex;
        align-items: center;
        justify-content: center;
        a{
          display: block;
          width: 200px;
          text-align: center;
          img{
            max-height: 85px;
          }
        }
        
    }
    .divider{
      width: 1px;
      height: 70px;
      background: $textBlack;
      @media (max-width: $small-phone) {
        width: 114px;
        height: 1px;
       }
    }
    }
    .se-hie-section-btns{
      margin-top: 70px;
      text-align: center;
    }
  }
}
.se-equity{
  padding: 35px 0 20px 0;
  background: rgba(243, 253, 253, 0.50);
  h2{
    color: #000;
    text-align: left;
    font-size: 48px;
    font-style: normal;
    font-weight: 600;
    line-height: 66px;
    margin-bottom: 20px;
  }
  p{
    color: #000;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 30.06px; /* 150.3% */
    letter-spacing: -0.09px;
    margin-bottom: 20px;
  }
  img{
    max-width: 100%;
  }
  .se-about-img-box{
    position: relative;
    height: 500px;
    background: url(../assets/images/wave-circle.png) no-repeat right bottom;
    @media (max-width:$small-laptop) {
      width: 100%;
    }
    @media (max-width: $phablet) {
      height: auto;
      margin-top: 50px; 
     }
    .about-img-box-1{
      position: absolute;
      top: 0;
      right: 15px;
      z-index: 9;
      width: 475px;
      height: 411px;
      overflow: hidden;
      border-radius: 36px;
      @media (max-width: $phablet) {
       position: static;
       width: 100%;
       height: auto; 
      }
    }
    .about-img-box-2{
      position: absolute;
      top: 190px;
      right: 325px;
      z-index: 10;
      width: 324px;
      height: 298px;
      overflow: hidden;
      border-radius: 36px;
      @media (max-width: $phablet) {
        position: relative;
        right: auto;
        top:-50px;
        width: 80%;
        height: auto;
        margin: auto;
      }
    }
  }
  &.pb-60{
    padding-bottom:90px ;
  }
}
.text-left{
  text-align: left !important;
}

.flip-card {
  background-color: transparent;
  height: 348px;
  perspective: 1000px;
  cursor: pointer;
}

.flip-card-inner {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.6s;
  transform-style: preserve-3d;
  box-shadow: 0px 4px 21px 1px rgba(48, 123, 196, 0.1);
}

.flip-card:hover .flip-card-inner {
  transform: rotateY(180deg);
}

.flip-card-front, .flip-card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.flip-card-front {
  font-size: 20px;
  font-weight: 500;
  letter-spacing: -0.39px;
  text-align: center;
  color: #274760;
  line-height: 25px;
  padding: 0 5px;
  border-radius: 10px;
  background:linear-gradient(100deg, #11adb3 0%, #5ee7df 100%);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  .flip-card-title{
    margin-bottom: 20px;
    color: #fff;
  }
  .flip-card-icon{
    margin-top: 100px;
    max-width: 90px;
    max-height: 90px;
  }
}

.flip-card-back {
  border-radius: 10px;
  background:#fff;
  transform: rotateY(180deg);
  padding: 15px;
  position: relative;
  .service-title-icon {
    position: absolute;
    left: 20px;
    top: 20px;
    width: 60px;
  }
  p.common-parah {
      margin-top: 80px;
      font-size: 15px;
  }
}
.b-slide-2 .mt-520{
  margin-top: 520px !important;
}
.se-text-toggel{
  transition: all 0.5s;
  max-height: 0;
  overflow: hidden;
  &.show{
    max-height: 2000px;
  }
}
.collap-btn{
  cursor: pointer;
}


.slide-content-1{
  h2{
    margin-top: 90px;
    @media (max-width: $small-tablet){
      margin-top: 15px;
    }
    @media (max-width: $phablet){
      font-size: 25px !important;
    }
  }
}

.Banner-Equinox-logos {
  position: relative;
  width: 678px;
  height: 125px;
  margin: 25px 0;
  max-width: 90%;
  @media (max-width: $small-tablet){
    margin: 10px 0;
    height: 100px;
  }
  @media (max-width: $small-phone){
    height: 75px;
  }
  img {
    position: absolute;
    right: 0;
    left: auto;
    max-width: 100%;
    bottom: 0;
    top: 0;
    margin: auto;
  }
  &:before {
    content: '';
    display: block;
    width: 1500px;
    height: 124px;
    position: absolute;
    background: #fff;
    right: 0;
    border-radius: 70px;
    box-shadow: 4px 4px 0 0 #21b2bc;
    @media (max-width: $small-tablet){
      height: 100px;
    }
    @media (max-width: $small-phone){
      height: 75px;
    }
    
  }
}